import { GridValidRowModel } from '@mui/x-data-grid';
import axios from 'axios';
import dayjs from 'dayjs';

import { trackException } from '@/application-insights';
import { FundingDetails } from '@/forms/contexts/budget-context';
import { FunderData, GetFundersResponse } from '@/forms/types';
import {
  CostEntityDto,
  CostingSection,
  CostingsDto,
  ExternalDataDto,
  FacilitiesAndServicesPeriod,
  NonStaffPeriod,
  Sections,
  StaffModelDto,
  StaffPeriod,
} from '@/types';
import { costingsDateFormat } from '@/utils';

const BASE_URL = '/api/preaward';

const preawardApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

preawardApi.interceptors.response.use(
  (response) => response,
  (error: Error) => trackException(error),
);

const getCostingsPath = (sectionName: string): string => {
  const sectionPaths: Record<string, string> = {
    [Sections.StaffCosts]: '/Staff/costings',
    [Sections.NonStaffCosts]: '/NonStaff/costings',
    [Sections.Partners]: '/Partner/costings',
    [Sections.FacilitiesAndServices]: '/FacilitiesAndServices/costings',
  };

  return sectionPaths[sectionName];
};

const getRequestBodyForSection = (sectionName: string, parentRow: GridValidRowModel): Record<string, unknown> => {
  const sectionBody: Record<string, unknown> = {
    [Sections.StaffCosts]: {
      fullName: parentRow?.Name as string,
      costHeading: parentRow['Cost Heading'] as string,
      projectRole: parentRow['Project Role'] as string,
      periods: (parentRow['Time&Effort'] as StaffPeriod[])?.map((period: StaffPeriod) => ({
        from: dayjs(period.From).format(costingsDateFormat),
        to: dayjs(period.To).format(costingsDateFormat),
        effort: period.Effort,
      })),
    },
    [Sections.NonStaffCosts]: {
      name: parentRow?.Item as string,
      costHeading: parentRow['Cost Heading'] as string,
      periods: (parentRow['Time&Costs'] as NonStaffPeriod[])?.map((period: NonStaffPeriod) => ({
        from: dayjs(period.From).format(costingsDateFormat),
        to: dayjs(period.To).format(costingsDateFormat),
        cost: period.Cost,
      })),
    },
    [Sections.FacilitiesAndServices]: {
      name: parentRow?.Item as string,
      costHeading: parentRow['Cost Heading'] as string,
      periods: (parentRow['Time&Consumption'] as FacilitiesAndServicesPeriod[])?.map(
        (period: FacilitiesAndServicesPeriod) => ({
          from: dayjs(period.From).format(costingsDateFormat),
          to: dayjs(period.To).format(costingsDateFormat),
          consumptionUnit: period['Consumption Units'],
          projectCost: period['Project Cost'],
        }),
      ),
    },
    [Sections.Partners]: {
      costHeading: parentRow['Cost Heading'] as string,
    },
  };

  return sectionBody[sectionName] as Record<string, unknown>;
};

const preawardServiceApi = {
  saveCostingsInForm: async (
    formId: string,
    updatedPageId: string,
    userProjectFormId: string,
    sections: CostingSection[],
    funderDetails: FundingDetails,
  ) => {
    const requestBody: {
      formId: string;
      pageId: string;
      userProjectFormId: string;
      sections: CostingSection[];
      pricingSettings: { funderId: string; pricingSchemeName?: string } | null;
    } = {
      formId,
      pageId: updatedPageId,
      userProjectFormId,
      sections,
      pricingSettings: null,
    };

    if (funderDetails.funderId && funderDetails.pricingSchemeName) {
      requestBody.pricingSettings = {
        funderId: funderDetails.funderId,
        pricingSchemeName: funderDetails.pricingSchemeName,
      };
    }

    const response = await preawardApi.post('/costings', requestBody);
    return response.status === 204;
  },

  getCostings: async (formId: string, pageId: string, userProjectFormId: string): Promise<CostingsDto> => {
    const response = await preawardApi.get(
      `/costings?formId=${formId}&pageId=${pageId}&userProjectFormId=${userProjectFormId}`,
    );
    return response.data as CostingsDto;
  },

  getSubmission: async (formId: string, pageId: string, submissionId: string): Promise<CostingsDto> => {
    const response = await preawardApi.get(
      `/submission?formId=${formId}&pageId=${pageId}&submissionId=${submissionId}`,
    );
    return response.data as CostingsDto;
  },

  getFunders: async (pageSize = 10, pageNumber = 1): Promise<FunderData[]> => {
    const path = `/Funder?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const response = await preawardApi.get(path);
    const data = response.data as GetFundersResponse;
    return data.funders;
  },

  getFunderById: async (funderId: string): Promise<FunderData> => {
    const path = `/Funder/${funderId}`;
    const response = await preawardApi.get(path);
    return response.data as FunderData;
  },

  getCostingsTable: async (
    sectionName: string,
    parentRow: GridValidRowModel,
    fundingDetails: FundingDetails,
  ): Promise<CostEntityDto> => {
    const path = getCostingsPath(sectionName);
    const requestBody = getRequestBodyForSection(sectionName, parentRow);
    requestBody.funderId = fundingDetails.funderId;
    requestBody.pricingSchemeName = fundingDetails.pricingSchemeName;
    const response = await preawardApi.post(path, requestBody);
    return response.data as CostEntityDto;
  },

  getSummary: async (
    formId: string,
    pageId: string,
    userProjectFormId: string,
    fundingDetails: FundingDetails,
  ): Promise<CostEntityDto> => {
    let path = `/Summary?formId=${formId}&pageId=${pageId}&userProjectFormId=${userProjectFormId}`;
    if (fundingDetails.funderId) {
      path = path.concat(`&funderId=${fundingDetails.funderId}`);
    }
    if (fundingDetails.pricingSchemeName) {
      path = path.concat(`&pricingSchemeName=${fundingDetails.pricingSchemeName}`);
    }
    const response = await preawardApi.get(path);
    return response.data as CostEntityDto;
  },

  getExternalData: async (path: string): Promise<ExternalDataDto> => {
    const response = await preawardApi.get(path);
    return response.data as ExternalDataDto;
  },

  getStaffByNameFilter: async (staffPath: string, name: string): Promise<StaffModelDto> => {
    const response = await preawardApi.get(`${staffPath}?name=${name}`);
    return response.data as StaffModelDto;
  },
};

export default preawardServiceApi;
