/* eslint-disable max-classes-per-file */
import { GridRowModel } from '@mui/x-data-grid-pro';
import { ReactNode } from 'react';

/* eslint-disable max-classes-per-file */

export enum ColumnValueType {
  none = '',
  text = 'text',
  number = 'number',
  dropdown = 'dropdown',
  calculated = 'calculated',
  datetime = 'datetime',
  table = 'table',
  external = 'external',
}

export enum Sections {
  StaffCosts = 'Staff Cost',
  NonStaffCosts = 'Non-Staff',
  FacilitiesAndServices = 'Facilities&Services',
  Partners = 'Partners',
}

export enum ErmCommandNames {
  isDirty = 'isDirty',
  formSaved = 'formSaved',
  saveForm = 'saveForm',
}

export enum Categories {
  Staff = 'Staff',
  NonStaff = 'Non-Staff',
}

export interface CostingsDto {
  formId: string;
  sections: CostingSection[];
  pricingSettings: PricingSettings;
}

export interface PricingSettings {
  funderId: string;
  pricingSchemeName?: string;
}

export interface CostingSection {
  id: string;
  name: string;
  categories: CostingCategory[];
}

export interface CostingCategory {
  id: string;
  name: string;
  columnDefinitions: ColumnDefinition[];
  rows: GridRowModel[];
}

export interface ColumnDefinition {
  $type: ColumnValueType;
  name: string;
}

export interface DropdownColumnOptions {
  options: DropdownColumnOption[];
}

export interface DropdownColumnOption {
  name: string;
  value: string;
}
export function isStaffModel(obj: unknown): obj is StaffModel {
  return (
    typeof obj === 'object' &&
    typeof (obj as StaffModel).firstName === 'string' &&
    typeof (obj as StaffModel).lastName === 'string' &&
    typeof (obj as StaffModel).organisationalUnit === 'string' &&
    typeof (obj as StaffModel).grade === 'string' &&
    typeof (obj as StaffModel).scalePoint === 'string' &&
    typeof (obj as StaffModel).payIncrease === 'string' &&
    typeof (obj as StaffModel).fullName === 'string'
  );
}

export interface StaffModel {
  firstName: string;
  lastName: string;
  organisationalUnit: string;
  grade: string;
  scalePoint: string;
  payIncrease: string;
  fullName: string;
}

export interface StaffModelDto {
  data: StaffModel[];
}

export interface ExternalData {
  name: string;
  unitCost?: number;
}

export interface ExternalDataDto {
  data: ExternalData[];
}

export interface CostEntity {
  Type: string;
  'Project Cost': string;
  'Funder Cost': string;
  Income: string;
  'Surplus/Deficit': string;
  Children: CostEntity[] | null;
}

export interface CostEntityDto {
  data: CostEntity[];
}

export interface StaffPeriod {
  From: Date;
  To: Date;
  Effort: number;
}

export interface NonStaffPeriod {
  From: Date;
  To: Date;
  Cost: number;
}

export interface FacilitiesAndServicesPeriod {
  From: Date;
  To: Date;
  'Consumption Units': number;
  'Project Cost': number;
}

export interface FacilitiesAndServicesData {
  name: string;
  unitCost: number;
}

export interface FacilitiesAndServicesDataDto {
  data: FacilitiesAndServicesData[];
}

class TableColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  columnDefinitions: ColumnDefinition[];

  constructor(name: string, columnDefinitions: ColumnDefinition[]) {
    this.$type = ColumnValueType.table;
    this.name = name;
    this.columnDefinitions = columnDefinitions;
  }
}

class DropdownColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  options: DropdownColumnOption[];

  constructor(name: string, dropdown: DropdownColumnOptions) {
    this.$type = ColumnValueType.dropdown;
    this.name = name;
    this.options = dropdown.options;
  }
}

class ExternalColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  path: string;

  propertyName: string;

  filterable: boolean;

  constructor(name: string, path: string, propertyName: string, filterable: boolean) {
    this.$type = ColumnValueType.text;
    this.name = name;
    this.path = path;
    this.propertyName = propertyName;
    this.filterable = filterable;
  }
}

export { DropdownColumnDefinition, TableColumnDefinition, ExternalColumnDefinition };

export interface ErmCommand {
  command: string;
  pageId: string;
}

export type ChildrenType = {
  children: ReactNode;
};

export interface SectionTotal {
  sectionId: string;
  sectionName: string;
  categoryId: string;
  total: number;
}

export interface ExternalDataType {
  name: string;
  path: string;
  propertyNames: string[];
  filterable: boolean;
}

export interface ExternalDataTypes {
  externalDataTypes: ExternalDataType[];
}

export enum Status {
  ADD,
  UPDATE,
}
class TextColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  constructor(name: string) {
    this.$type = ColumnValueType.text;
    this.name = name;
  }
}

class DateTimeColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  constructor(name: string) {
    this.$type = ColumnValueType.datetime;
    this.name = name;
  }
}

class NumberColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  constructor(name: string) {
    this.$type = ColumnValueType.number;
    this.name = name;
  }
}

class CalculatedColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  expression: string;

  constructor(name: string, expression: string) {
    this.$type = ColumnValueType.calculated;
    this.name = name;
    this.expression = expression;
  }
}

export { TextColumnDefinition, DateTimeColumnDefinition, NumberColumnDefinition, CalculatedColumnDefinition };

declare module '@mui/x-data-grid-pro' {
  interface FooterPropsOverrides {
    total: number;
  }

  interface ColumnMenuPropsOverrides {
    handleEditColumn: (headerName: string) => void;
    handleDeleteColumn: (headerName: string) => void;
  }
}
