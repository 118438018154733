import { Box, alpha } from '@mui/material';
import { DataGridPro, DataGridProProps, GridColDef, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { Guid } from 'typescript-guid';

import { useBudgetStateContext } from '@/forms/contexts/budget-context';
import { CostEntity } from '@/types';
import { alignValue, costingGridColumnDefinition, formatValueCell } from '@forms/utils';

function SummaryTable() {
  const { summaryTable } = useBudgetStateContext();
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const isTestEnv = process.env.NODE_ENV === 'test';

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    ...costingGridColumnDefinition,
    headerName: 'Type',
    flex: 0.4,
    hideDescendantCount: true,
  };

  function setCostingColumns(data: CostEntity[]) {
    const cols: GridColDef[] = [];
    data.forEach((item) => {
      const keys = Object.keys(item).filter((key) => key !== 'Children' && key !== 'Type');
      cols.push(
        ...keys.map((key) => ({
          ...costingGridColumnDefinition,
          field: key,
          headerName: key,
          type: 'number',
          flex: 0.15,
          headerAlign: alignValue(key),
          align: alignValue(key),
          renderCell: (params: GridRenderCellParams) => formatValueCell(params.value as number),
        })),
      );
      return null;
    });

    setColumns(cols);
  }

  const flattenWithDepthAndHierarchy = useCallback(
    (node: CostEntity[], result: GridRowModel[] = [], depth = 0, parentHierarchy: string[] = []) => {
      node.forEach((child) => {
        const flattened = { ...(child as GridRowModel) };
        flattened.id = Guid.create().toString();
        flattened.depth = depth;
        flattened.Hierarchy = [...parentHierarchy, flattened.Type];
        result.push(flattened);
        if (((flattened.Children as CostEntity[])?.length ?? 0) > 0) {
          flattenWithDepthAndHierarchy(
            flattened.Children as CostEntity[],
            result,
            depth + 1,
            flattened.Hierarchy as string[],
          );
        }
      });
      return result;
    },
    [],
  );

  useEffect(() => {
    setCostingColumns(summaryTable);
    setRows(flattenWithDepthAndHierarchy(summaryTable));
  }, [summaryTable, flattenWithDepthAndHierarchy]);

  return (
    <Box
      id="summaryTable"
      sx={{
        width: '100%',
        '& .MuiDataGrid-root': {
          border: '1px solid rgba(224, 224, 224, 1)',
          borderRadius: 2,
          overflow: 'hidden',
        },
      }}
    >
      <DataGridPro
        sx={(theme) => ({
          '.hierarchyLevel1': {
            bgcolor: alpha(theme.palette.primary.main, 0.08),
            fontWeight: 'bold',
          },
          '.hierarchyLevel2': {
            bgcolor: alpha(theme.palette.primary.main, 0.06),
            fontWeight: 600,
          },
          '.hierarchyLevel3': {
            bgcolor: alpha(theme.palette.primary.main, 0.04),
          },
          '.hierarchyLevel4': {
            bgcolor: alpha(theme.palette.primary.main, 0.02),
          },
          '& .MuiDataGrid-cell': {
            borderColor: alpha(theme.palette.primary.main, 0.1),
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
        })}
        getRowClassName={(params) => {
          const { depth } = params.row as { depth: number };
          const depthClass: { [key: number]: string } = {
            0: 'hierarchyLevel1',
            1: 'hierarchyLevel2',
            2: 'hierarchyLevel3',
            3: 'hierarchyLevel4',
          };
          return depthClass[depth];
        }}
        treeData
        getTreeDataPath={(row) => (row as { Hierarchy: string[] }).Hierarchy}
        groupingColDef={groupingColDef}
        defaultGroupingExpansionDepth={2}
        autoHeight
        rowHeight={48}
        columnHeaderHeight={56}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        hideFooterPagination
        hideFooter
        hideFooterRowCount
        hideFooterSelectedRowCount
        disableColumnReorder
        disableVirtualization={isTestEnv}
        density="compact"
      />
    </Box>
  );
}

export default SummaryTable;
