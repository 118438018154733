import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
  ...['/*', '/preaward/*'].map((path) => ({
    path,
    Component: App,
  })),
]);

root.render(<RouterProvider router={router} />);
